@import 'assets/scss/mixins/breakpoints.scss';
@import 'components/shared/FormControl/FormControl.module.scss';

.formControl {
  @include formControlBasicStyle();
  height: 114px;
  padding: 10px;
  resize: none;

  &.primary {
    border: 1px solid $input-border;
  }
  &:focus {
    box-shadow: 0px 0px 0px 1px $dark-gray;
  }
  &.error {
    box-shadow: 0px 0px 0px 1px $link-color;
    background: rgba(204, 0, 0, 0.1);
  }
}
.errorTitle {
  padding-top: 5px;
  display: flex;
  .iconDanger {
    font-size: $fsXl;
    color: $link-color;
    padding-right: 5px;
    align-items: center;
  }
}
