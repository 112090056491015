@import 'assets/scss/mixins/utils.scss';
@include visuallyHidden();

.content {
  margin-bottom: 0;
  display: flex;
  // To fix the label not being overridden by the input
  padding-left: 0 !important;
}

.inputPlaceholder {
  input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    min-height: 37px;
  }
}
