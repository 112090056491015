@import 'assets/scss/mixins/breakpoints.scss';

.column {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  padding-right: 5px;
  padding-left: 5px;
  .column {
    padding-right: 0;
    padding-left: 0;
  }
  @include xs {
    flex-basis: 100%;
    padding-right: 0;
    padding-left: 0;
  }

  &.unEqual {
    flex: 0 0 auto;
    padding-right: 10px;
    @include xs {
      flex: 1;
      padding-right: 0;
    }
  }
  &.unEqualNoPadding {
    flex: 0 0 auto;
    @include xs {
      flex: 1;
    }
  }
  &.pr10 {
    padding-right: 10px;
  }
  &.noMargin {
    margin: 0;
  }
  &.noPadding {
    padding: 0;
  }
  &.mb20 {
    margin-bottom: 20px;
  }
  &.xsMb20 {
    @include xs {
      margin-bottom: 20px;
    }
  }

  &.keepPadding {
    padding-right: 5px;
  }
  &.keepPadding ~ * {
    padding-left: 5px;
  }
}
