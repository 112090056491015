@import 'assets/scss/variables.scss';
.formGroup {
  flex-grow: 1;
  > label {
    font-size: 11px;
    color: $dark-gray;
    padding-left: 5px;
  }
  &.labelMd {
    > label {
      padding: 0;
      font-size: $lg;
      margin-bottom: 8px;
    }
  }

  p {
    margin: 5px 0 0;
    color: $dark-gray;
  }
}
