@import 'assets/scss/mixins/utils.scss';
@import 'assets/scss/mixins/breakpoints.scss';

.w100 {
  width: 100%;
}

.linkStyling {
  @include flex-center();
}

.pointer {
  cursor: pointer;
}
