@import 'assets/scss/mixins/breakpoints.scss';
@import 'components/shared/FormControl/FormControl.module.scss';

.inputWrapper {
  flex-grow: 1;
  > * {
    @include formControlBasicStyle();
    padding: 6px 10px;
    height: 36px;

    // Not sure this is used ⬇️
    &.primary {
      border: 1px solid $input-border;
    }

    @include formControlFocusStyle();

    .borderNone {
      border: none;
    }
  }
}
