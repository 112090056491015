@import 'assets/scss/variables.scss';
.infoLabel {
  border-radius: 4px;
  padding: 1px 5px;
  line-height: 16px;
  margin: 0;
  display: inline-flex;
  &.primary {
    background: $link-color;
  }
  &.secondary {
    background: $info-bg;
    font-family: $font-family-medium;
    padding: 4px 8px;
  }
  &.blue {
    background: $primary;
  }
  &.success {
    background: $success-color;
  }
}
