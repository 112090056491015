@import 'assets/scss/mixins/breakpoints.scss';
@import 'assets/scss/mixins/utils.scss';
@keyframes fadeInDown {
  0% {
    transform: translateY(-200px);
  }
  100% {
    transform: translateY(0);
  }
}
.dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  overflow-y: auto;
  overflow-x: hidden;
  visibility: hidden;
  background: $dialog-bg;

  &.show {
    z-index: 99999;
    visibility: visible;
  }
  .dialogWrapper {
    position: relative;
    padding: 15px;
    opacity: 0;
    width: auto;
    display: flex;
    transform: translate(0, 0);
    align-items: center;
    min-height: calc(100% - (1.75rem * 2));
    &.fade {
      animation-name: fadeInDown;
      animation-duration: 0.3s;
      animation-fill-mode: both;
      transition: all 0.3s;
      opacity: 1;
      margin: auto;
    }
    &:before {
      height: calc(100vh - (1.75rem * 2));
      content: '';
    }
    &.jumbo {
      max-width: 1440px;
      @include ie() {
        max-width: 1440px;
      }
    }
    &.xxxl {
      max-width: 1102px;
      @include ie() {
        max-width: 1102px;
      }
    }
    &.xxl {
      max-width: 1020px;
      @include ie() {
        max-width: 1020px;
      }
    }
    &.xl {
      max-width: 900px;
      @include ie() {
        max-width: 900px;
      }
    }
    &.lg {
      max-width: 730px;
    }
    &.md {
      max-width: 600px;
    }
    &.sm {
      width: 320px;
    }
    .dialogContent {
      box-shadow: $dialog-box-shadow;
      background: $white;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      border-radius: 4px;
      overflow: hidden;
      position: relative;
      width: 100%;
      .dialogClose {
        display: flex;
        margin-left: auto;
        padding: 15px 15px 5px;
      }
      .dialogHeader {
        background: $hover-bg;
        padding: 12px 20px;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .closeButton {
          color: $dark-gray;
          font-size: $medium-font;
          font-family: MotivasanRegular;
        }
      }
      &.findAccount {
        overflow: visible;
      }
    }
    @include xs {
      padding: 10px;
    }
  }
}
