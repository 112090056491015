@import 'assets/scss/variables.scss';
@import 'assets/scss/mixins/utils.scss';

.fieldError {
  padding-top: 5px;
  display: flex;
  @include ie() {
    display: inline-block;
  }
}

.icon {
  font-size: $fsXl;
  color: $link-color;
  padding-right: 5px;
  align-items: center;
  @include ie() {
    padding-right: 0;
  }
}
