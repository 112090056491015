@import 'assets/scss/variables.scss';
@import 'assets/scss/mixins/utils.scss';

//#   ---------------------------
//#   Common styles
//#   ---------------------------
.radioButton {
  min-height: 19px;
  display: flex;
  align-items: center;
  label {
    min-height: 15px;
  }
  [type='radio']:checked,
  [type='radio']:not(:checked) {
    // Hides the default radio button
    position: absolute;
    left: -9999px;

    // Default position of the label
    + label {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 15px;
      display: inline-block;
      color: $dark-gray;
      margin-bottom: 0;
      font-size: $md;
    }

    // Custom outer circle
    + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 15px;
      height: 15px;
      border-radius: 100%;
      background: #fff;
      transition: all 0.2s ease;
    }

    // Custom inner circle
    + label:after {
      content: '';
      width: 9px;
      height: 9px;
      background: $link-color;
      position: absolute;
      top: 3px;
      left: 3px;
      border-radius: 100%;
    }

    &:disabled + label {
      opacity: 0.7;
      cursor: not-allowed;
    }

    &:checked:disabled {
      + label:after {
        @include disabled-styles();
        border: solid $light-gray;
        border-width: 0 1px 1px 0;
      }
      + label:before {
        @include disabled-styles();
        border-color: $light-gray;
      }
    }
  }

  //#   ---------------------------
  //#   Unchecked styles
  //#   ---------------------------
  [type='radio']:not(:checked) {
    // for the outer circle
    + label:before {
      border: 1px solid $dark-gray;
    }
    // for the inner circle
    + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    &:focus {
      + label:before {
        box-shadow: 0px 0px 0px 1px $dark-gray;
      }
    }
  }

  //#   ---------------------------
  //#   Checked styles
  //#   ---------------------------
  [type='radio']:checked {
    + label:before {
      border: 1px solid $link-color;
    }
    + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    &:focus {
      + label:before {
        box-shadow: 0px 0px 0px 1px $link-color;
      }
    }
  }

  [type='radio']:checked:focus
    [type='radio']:checked
    [type='radio']:checked
    + label {
    font-weight: bold;
  }
  label > span {
    font-size: $md;
    left: 0;
    top: 0;
    &:not(:last-child) {
      padding-right: 5px;
    }
  }
}
