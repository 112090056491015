@import 'assets/scss/variables.scss';

.h1 {
  font-size: 28px;
}
.h2 {
  font-size: 24px;
}
.h3 {
  font-size: 20px;
}
.h4 {
  font-size: 18px;
}
.h5 {
  font-size: 16px;
}
.h6 {
  font-size: $md;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: $dark-gray;
  margin: 0;
  &.primary {
    color: $link-color;
  }
  &.secondary {
    color: $light-gray;
  }
  &.tertiary {
    color: $medium-gray;
  }
  &.black {
    color: $black;
  }
  &.lineThrough {
    text-decoration: line-through;
  }
  &.fontWeightNormal {
    font-weight: normal;
  }
}
