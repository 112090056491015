@import 'assets/scss/mixins/breakpoints.scss';
@import 'assets/scss/mixins/utils.scss';

.formField {
  flex-grow: 1;
  .fieldSection {
    display: flex;
    flex-direction: column;
    .requiredWrapper {
      display: flex;
      .iconRequired {
        color: $link-color;
        padding-left: 3px;
        font-size: 18px;
        display: flex;
        align-items: center;
      }
    }
    label {
      font-size: 14px;
      color: $dark-gray;
      padding-left: 5px;
      &.labelMd {
        padding: 0;
        font-size: $lg;
        margin-bottom: 8px;
      }
      &[data-input='checkbox'] {
        padding-left: 0;
        font-size: $body-font;
        color: $light-gray;
      }
    }
    .labelXs {
      font-size: $xs;
    }
  }

  .errorTitle {
    padding-top: 5px;
    display: flex;
    span:first-child {
      font-size: $fsXl;
      color: $link-color;
      padding-right: 5px;
    }
  }
}
