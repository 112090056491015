@import 'assets/scss/variables.scss';

.card {
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0 0 11px $box-shadow;
    transition: all 0.3s ease-in-out 0s;
  }

  &.noBoxShadowOnHover {
    &:hover {
      box-shadow: none;
    }
  }
}
