@import 'assets/scss/variables.scss';
@import 'assets/scss/mixins/utils.scss';

.checkBox {
  display: block;
  margin-bottom: 10px;

  &.m0 {
    margin: 0;
  }

  &.categoryList {
    margin-bottom: 10px;

    label {
      line-height: 16px;
      color: $dark-gray;
      transition: all 0.2s ease-in-out;

      &:before {
        margin-right: 6px;
      }

      label:before,
      label:after,
      input:checked + label:after {
        content: none;
      }

      label {
        &::after,
        &::before {
          content: none;
        }

        input:checked {
          & + {
            label {
              &::after {
                content: none;
              }
            }
          }
        }
      }
    }

    input:checked {
      & + {
        label {
          &::after {
            top: 1px;
          }
        }
      }
    }
  }

  [data-label-select-none='1'] {
    @include userSelectNone;
  }

  input {
    transition: all 0.2s ease-in-out;
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    opacity: 0;
    cursor: pointer;
    position: absolute;
    outline: 0;

    &:focus {
      + label:before {
        box-shadow: 0 0 0 1px $dark-gray;
      }
      + span:before {
        box-shadow: 0 0 0 1px $dark-gray;
      }
    }

    &:disabled + label {
      opacity: 0.7;
    }

    &:checked + label {
      font-weight: bold;
    }

    &:checked:disabled {
      + label:after {
        @include disabled-styles();
        border: solid $light-gray;
        border-width: 0 1px 1px 0;
      }
      + label:before {
        @include disabled-styles();
        border-color: $light-gray;
      }
    }

    &:checked {
      + label:before {
        border: 1px solid $link-color;
      }

      + label:after {
        content: '';
        display: block;
        position: absolute;
        top: 1px;
        left: 6px;
        width: 6px;
        height: 12px;
        border: solid $link-color;
        border-width: 0 1px 1px 0;
        transform: rotate(45deg);
      }
      &:focus {
        + label {
          &:before {
            box-shadow: 0 0 0 1px $link-color;
          }
        }
      }
    }
  }

  label {
    position: relative;
    cursor: pointer;
    font-size: $md;
    margin: 0;
    display: inline-flex;
    padding: 0;

    &:before {
      transition: all 0.2s ease-in-out;
      content: '';
      -webkit-appearance: none;
      background-color: transparent;
      border: 1px solid $light-gray;
      box-shadow: $label-checkbox-shadow;
      padding: 8px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 5px;
      border-radius: 2px;
      height: 16px;
      width: 16px;
    }
  }
}

.notAllowedCursor {
  &:hover {
    label {
      cursor: not-allowed;
      &:before {
        cursor: not-allowed;
      }
    }
  }
}
