@import 'assets/scss/mixins/breakpoints.scss';
@import 'components/shared/FormControl/FormControl.module.scss';

.formControl {
  @include formControlBasicStyle();
  padding: 6px 10px;
  height: 36px;

  // Not sure this is used ⬇️
  &.primary {
    border: 1px solid $input-border;
  }

  @include formControlFocusStyle();

  &.error {
    @include formControlErrorStyle();
  }
}

.textInputWrapper {
  transition: all 0.2s ease-in-out;
}

.inputFlex {
  transition: all 0.2s ease-in-out;
  display: flex;
  span {
    display: inline-flex;
    padding: 9px 21px;
    margin-left: -8px;
    cursor: pointer;
    background: $dark-gray;
    align-items: center;
    font-size: 18px;
    color: $white;

    @include sm {
      padding: 9px 15px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
    @include xs {
      margin-left: 0;
    }
  }
}

.errorTitle {
  padding-top: 5px;
  display: flex;
  .iconDanger {
    font-size: $fsXl;
    color: $link-color;
    padding-right: 5px;
    align-items: center;
  }
}

.iconButton {
  &:focus {
    span {
      outline: 0;
      box-shadow: $white 0px 0px 0px 1px inset;
    }
  }
}

.hideLabel {
  display: none;
}
