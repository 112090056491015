@import 'assets/scss/mixins/breakpoints.scss';

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
  width: 100%;
  &.noWrap {
    flex-wrap: nowrap;
  }
  .row {
    margin-right: 0;
    margin-left: 0;
  }
  .noWrap {
    flex-wrap: nowrap;
  }
  @include xs {
    margin-right: 0;
    margin-left: 0;
  }
}
.mb20 {
  margin-bottom: 20px;
}
.mb10 {
  margin-bottom: 10px;
}
@include xs {
  .xsMb20 {
    margin-bottom: 20px;
  }
}
